import React, { useEffect } from "react";
import PropTypes from "prop-types";

export default function HubspotForm({ region = "na1", formId, portalId, containerId, onFormSubmit }) {
  useEffect(async () => {
    await window.hbspt.forms.create({
      region: region,
      portalId: portalId,
      formId: formId,
      target: `#${containerId}`,
    });
  }, [formId, portalId, containerId]);

  useEffect(() => {
    if (onFormSubmit) {
      window.addEventListener("message", (event) => {
        if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormSubmit") {
          onFormSubmit();
        }
      });
    }
  }, [onFormSubmit]);

  return <div className="hbspt-form" id={containerId}></div>;
}

HubspotForm.propTypes = {
  formId: PropTypes.string.isRequired,
  portalId: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func,
};
HubspotForm.defaultProps = {
  region: "na1",
};
